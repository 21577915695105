import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

export const isAuthenticated: CanActivateFn = (): boolean | UrlTree => {
  return inject(AuthService).isAuthenticated() ? true : inject(Router).parseUrl('login');
};

export const isNotAuthenticated: CanActivateFn = (): boolean | UrlTree => {
  return !inject(AuthService).isAuthenticated() ? true : inject(Router).parseUrl('members');
};
