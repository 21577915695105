import { ErrorHandler, inject, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '@pd/ngx-components';
import * as Sentry from '@sentry/angular';
import { environment } from '../../environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private injector = inject(Injector);

  constructor() {
    if (environment.production) {
      Sentry.init({
        dsn: environment.sentry.dsn,
      });
    }
  }

  handleError(error: Error | HttpErrorResponse): void {
    if (environment.production) {
      Sentry.captureException(error);
      Sentry.showReportDialog();
    }
    const notificationService = this.injector.get(NotificationService);
    notificationService.showError('Es ist ein unerwarteter Fehler aufgetreten.');
    console.error(error);
  }
}
