import { inject, Injectable } from '@angular/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { AuthenticateGQL } from '../graphql/generated';
import { Router } from '@angular/router';
import { NotificationService } from '@pd/ngx-components';
import { lastValueFrom } from 'rxjs';

type JWT = JwtPayload & {
  userId: number;
};

export const LS_KEY_JWT = 'jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private router = inject(Router);
  private notificationService = inject(NotificationService);
  private authenticateGQL = inject(AuthenticateGQL);
  private jwtPayload: JWT | undefined;

  private getJWT(): string | undefined {
    return localStorage.getItem(LS_KEY_JWT) ?? undefined;
  }

  private setJWT(jwt: string) {
    localStorage.setItem(LS_KEY_JWT, jwt);
    this.jwtPayload = undefined;
  }

  private removeJWT() {
    localStorage.removeItem(LS_KEY_JWT);
    this.jwtPayload = undefined;
  }

  private getJWTPayload(): JWT | undefined {
    if (this.jwtPayload !== undefined) {
      return this.jwtPayload;
    }

    const jwt = this.getJWT();
    if (jwt === undefined) {
      return;
    }

    try {
      this.jwtPayload = jwtDecode<JWT>(jwt);
      return this.jwtPayload;
    } catch {
      this.handleInvalidAuth();
      return;
    }
  }

  async login(username: string, password: string) {
    const authenticate$ = this.authenticateGQL.mutate({
      data: {
        username,
        password,
      },
    });

    const response = await lastValueFrom(authenticate$);
    if (response.data?.authenticate.success) {
      this.setJWT(response.data.authenticate.jwt);
      this.router.navigate(['members']);
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.removeJWT();
    this.router.navigate(['login']);
  }

  isAuthenticated(): boolean {
    const decodedJWT = this.getJWTPayload();

    if (decodedJWT === undefined) {
      return false;
    }

    const expireTime = decodedJWT.exp ?? 0;
    return expireTime > Math.floor(Date.now() / 1000);
  }

  getUserId() {
    return this.getJWTPayload()!.userId;
  }

  handleInvalidAuth() {
    this.notificationService.showError('Sitzung ungültig. Bitte melden Sie sich erneut an.');
    this.logout();
  }
}
