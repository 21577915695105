import { ApolloClientOptions, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';
import { HttpBatchLink, HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { setContext } from '@apollo/client/link/context';
import { HttpHeaders } from '@angular/common/http';
import { inject } from '@angular/core';
import { LS_KEY_JWT } from './auth/auth.service';

const uri = environment.api;

export function createApollo(): ApolloClientOptions<NormalizedCacheObject> {
  const authLink = setContext(() => {
    const jwt = localStorage.getItem(LS_KEY_JWT);
    return jwt ? { headers: new HttpHeaders({ Authorization: jwt }) } : {};
  });

  const httpLink = inject(HttpLink);
  const httpLinkHandler = httpLink.create({
    uri,
    headers: new HttpHeaders({
      'Apollo-Require-Preflight': 'true',
    }),
    extractFiles: body => extractFiles(body, isExtractableFile),
  });

  const httpBatchLink = inject(HttpBatchLink);
  const httpBatchLinkHandler = httpBatchLink.create({
    uri,
    headers: new HttpHeaders({
      'Apollo-Require-Preflight': 'true',
    }),
  });

  const apolloLink = ApolloLink.split(
    operation => operation.getContext().useMultipart,
    httpLinkHandler,
    httpBatchLinkHandler,
  );

  return {
    link: ApolloLink.from([authLink, apolloLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
  };
}
