import { ApplicationConfig, DEFAULT_CURRENCY_CODE, ErrorHandler } from '@angular/core';
import { provideRouter, TitleStrategy } from '@angular/router';
import { routes } from './app.routes';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { GlobalErrorHandler } from './misc/global-error-handler';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { de } from 'date-fns/locale';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PageTitleStrategy } from './page-title-strategy';
import { provideApollo } from 'apollo-angular';
import { createApollo } from './apollo-client';
import { authInterceptor } from './auth/auth.interceptor';

export const appName = 'BetterPSA';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MAT_DATE_LOCALE, useValue: de },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: MAT_TABS_CONFIG,
      useValue: { animationDuration: '0ms', stretchTabs: false },
    },
    { provide: TitleStrategy, useClass: PageTitleStrategy },
    provideHttpClient(withInterceptors([authInterceptor])),
    provideAnimations(),
    provideRouter(routes),
    provideApollo(createApollo),
    provideDateFnsAdapter(),
  ],
};
