import { Routes } from '@angular/router';
import { isAuthenticated, isNotAuthenticated } from './auth/auth.guards';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () => import('./views/login/login.component').then(m => m.LoginComponent),
    canActivate: [isNotAuthenticated],
  },
  {
    path: 'members',
    loadChildren: () => import('./views/members/members.routes'),
    canActivate: [isAuthenticated],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
